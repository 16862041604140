import 'core-js';
import BrandPage from 'brand/src/pages/page';

class StorefrontPage extends BrandPage {
	constructor() {
		super('storefront');
	}
}

export default StorefrontPage;
